<template>
    <div class="recreDetails">
      <!--头部banner-->
      <div class="hd_head">
        <div class="hd_head_fanhui">
          <van-icon name="arrow-left" size="22" color="#141212" @click="revert()"   />
          <!--<van-icon name="weapp-nav" size="22" @click="share()" color="#141212"    />-->

          <!--<img src="./img/fanhui.png"   alt="">-->
          <!--<img src="./img/gengduo.png" @click="share()" alt="">-->
        </div>
        <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000"  @change="onChange">
          <van-swipe-item  v-for="(item,index) in content.attachments" :key="item.attachmentId">
            <div class="hd_banner" v-show="item.fileType==1">
              <van-image :src="item.url">
                <template v-slot:error>加载失败</template>
              </van-image>
            </div>
            <div class="hd_banner" v-show="item.fileType==2">
              <video width="375px" height="225px" :src="item.url" controls="controls">
              </video>
            </div>
          </van-swipe-item>
          <van-swipe-item  v-if="content.attachments.length==0">
            <div class="hd_banner"  >
              <van-image :src="content.cover">
                <template v-slot:error>加载失败</template>
              </van-image>
            </div>
          </van-swipe-item>

        </van-swipe>
        <!--<div class="recr_swiper_icon">-->
          <!--<div class="recr_swiper_icon_item " :class="iconNum%2==0?'recr_swiper_icon_color':''"></div>-->
          <!--<div class="recr_swiper_icon_item" :class="iconNum%2==1?'recr_swiper_icon_color':''"></div>-->
        <!--</div>-->
      </div>



      <div class="hd_cont">
        <div class="td_title_head">
          <div class="td_title_head_left">
            <span>{{content.name}}</span>
          </div>
          <div class="td_title_head_right">
            <span>{{content.heatValue}}</span>
          </div>
        </div>
        <div class="td_title_city">
          <div class="td_title_city_left">
            <span>电话：{{content.telephone}}</span>
          </div>
          <div class="td_title_city_right" @click="callphone()">
            <img src="./img/boda.png" alt="">
            <span>拨打</span>
          </div>
        </div>
        <div class="td_line"></div>
        <div class="td_title_city">
          <div class="td_title_city_left">
            <span>地址：{{content.address}}</span>
          </div>
          <div class="td_title_city_right">
            <img src="./img/daohang.png" alt="">
            <span><a style="color: #141212" :href="' http://uri.amap.com/marker?position='+ params.longitude + ',' + params.latitude">导航</a></span>
          </div>
        </div>
      </div>
      <!--分割线-->
      <div class="td-brd" v-if="list.length"></div>
      <div class="re_title" v-if="list.length">
        套餐详情
      </div>
      <div class="td_tiket_list" v-for="item in list" @click="breakFunc(item)">
        <div class="td_tiket_list_head">
          <span>{{item.name}}</span>
          <span>￥{{item.sellPrice/100}}</span>
        </div>
        <div class="td_tiket_list_bot">
          <span>{{item.remark}}</span>
          <span>原价 ￥{{item.shopPrice/100}}</span>
        </div>
      </div>

      <!--分割线-->
      <div class="td-brd"></div>
      <sh-comment
        :isPraise="content.isPraise"
        :isCollect="content.isCollect"
        :bizId="params.sellerId"
        :bizType="bizType"
        @praise="praise"
        @collect="collect"
        :star="content.star"
        :collectNum="content.collect"
        :praiseNum="content.praise"
      >

      </sh-comment>



    </div>

</template>

<script src="./recreDetails.js">

</script>

<style scoped src="./recreDetails.css">

</style>
